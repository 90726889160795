import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { actions as routeActions } from '@slices/routeSlice';
import { Link, navigate, PageProps } from 'gatsby';
import { Heading, Flex, Para, Box, Button, Label } from 'workspace-core-ui';
import Layout from '@containers/Layout';
import BodyWrapper from '@components/BodyWrapper';
import { setHeaderType } from '@slices/gameStateSlice';
import useTranslation from '@hooks/useTranslation';
import useSound from '@hooks/useSound';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import ControlCenter, {
  ControlResponse,
} from '@components/controls/ControlCenter';
import { Interrupt, Content, ControlData, Claim } from 'types';
import { logItem } from '@slices/loggingSlice';
import useNavigateLog from '@hooks/useNavigateLog';
import getSizedParaVariant from '@utils/getSizedParaVariant';
import { motion } from 'framer-motion';
import { FADE_IN_CONTAINER, FADE_IN_ITEM, WINDOW_HASH } from '@sharedConstants';
import BackgroundImage from '@components/BackgroundImage';
import getSymbol from '@utils/getSymbol';
import ControlImage from '@components/controls/ControlImage';
import { grabImage } from '@utils/grabImage';
import { selectCurrentModule, selectProfile } from '../module/profile-slice';

const MotionLink = motion(Link);
export type InterruptContext = {
  url: string;
  isFollowup?: boolean;
  screenData: Interrupt<Content, ControlData<Content[]>> &
  Claim<Content, ControlData<Content[]>>;
}

const InterruptPage = (
  props: PageProps<Queries.InterruptPageQuery, InterruptContext>,
) => {
  const backgroundSymbol = getSymbol(props.data.interruptPageBackgroundSymbol);
  const [startTimestamp, setStartTimestamp] = useState<number>();
  const { playSound } = useSound();
  const [submittedAnswer, setSubmittedAnswer] =
    useState<ControlResponse | undefined>(undefined);
  const { nextRoute } = useAppSelector(state => state.route);
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const currentModule = useAppSelector(selectCurrentModule);
  const {
    Interrupt_Text,
    Claim_Text = null,
    Controls,
    // this blurb is used for followups, you get these when you answer incorrectly, something like "how does this make you feel: incorrect_text"
    Incorrect_Text,
  } = props.pageContext?.screenData;

  const { url, isFollowup } = props.pageContext;
  const { t, g } = useTranslation();
  const backgroundColor = isFollowup ? 'secondary' : 'background';
  const bodyText = isFollowup ? Incorrect_Text : Interrupt_Text;

  const normalizedQuestionName = isFollowup
    ? Claim_Text?.Content_Type || ''
    : Interrupt_Text?.Content_Type || '';

  useNavigateLog({
    questionName: normalizedQuestionName,
  });

  useLayoutEffect(() => {
    // record when user officially "sees" the question
    setStartTimestamp(Date.now());
  }, []);

  useEffect(() => {
    dispatch(setHeaderType({ headerType: 'minimal' }));
    dispatch(routeActions.inferNextRoute({ compareAgainst: url, profile, module: currentModule?.Name }));
  }, [dispatch, url, profile]);

  // TODO: mb this can be a hook
  useEffect(() => {
    if (submittedAnswer) {
      const endTimestamp = Date.now();
      // TODO: unify control center returns, so we can submit agnostically
      // dispatch(questionHasBeenAnswered({ submittedAnswer }));
      dispatch(
        logItem({
          question_name: normalizedQuestionName,
          question_type:
            `${Controls?.Control_Type}${isFollowup ? '-followup' : ''}` || '',
          // interrupts have no "results" but we still need to provide one for schema to work
          result: '',
          collection_name: 'answers',
          answer_text: submittedAnswer.controlValue.toString(),
          duration_in_seconds: startTimestamp
            ? (endTimestamp - startTimestamp) / 1000
            : 0,
        }),
      );

      // NOTE: this is done because binary questions do not "autoroute" like likerts. Likerts contain the href to the next entry in and of themselves
      if (
        Controls?.Control_Type === 'Unary' ||
        Controls?.Control_Type === 'Binary' ||
        Controls?.Control_Type === 'Trinary'
      ) {
        navigate(nextRoute?.url + WINDOW_HASH || `/${WINDOW_HASH}`, {
          replace: true,
        });
      }
    }
    // ignore exhaustive check for startDate as we don't care if it updates or not (atleast not in terms of rendering)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, nextRoute?.url, submittedAnswer]);

  return (
    <Layout backgroundColor={backgroundColor} data-cy="interrupt">
      {backgroundSymbol && (
        <BackgroundImage
          imageData={backgroundSymbol.data}
          imageType={backgroundSymbol.type}
        />
      )}
      <BodyWrapper p={5}>
        <motion.div
          style={{ display: 'contents' }}
          variants={FADE_IN_CONTAINER}
          initial="hidden"
          animate="show"
        >
          <Flex flex={0.5} />
          {Controls?.Header_Text?.Symbol && (
            <ControlImage
              {...grabImage(Controls?.Header_Text?.Symbol)}
              sx={{
                svg: {
                  width: 'clamp(100px, 70vw - 1rem, 400px)',
                  height: 'clamp(100px, 40vh - 1rem, 400px)',
                },
              }}
            />
          )}
          <Flex
            flexDirection="column"
            flex={0.5}
            alignItems="center"
            minWidth="50vmax"
            maxWidth="1100px"
            alignSelf="center"
          >
            <Box p={5} width="100%">
              <Heading
                as={motion.h2}
                variants={FADE_IN_ITEM}
                variant="h2"
                mb={4}
              >
                <CustomMdxRenderer>
                  {g(Controls?.Header_Text, true)}
                </CustomMdxRenderer>
              </Heading>
              <Para
                as={motion.div}
                variants={FADE_IN_ITEM}
                sx={{
                  // similar treatment used in ResultsView
                  '.gatsby-resp-image-wrapper': {
                    width: '300px',
                    m: 3,
                  },
                }}
                variant={getSizedParaVariant(g(bodyText), 'p2')}
              >
                <CustomMdxRenderer>
                  {/* it can be the claim blurb if this interrupt is a followup */}
                  {g(bodyText, true)}
                </CustomMdxRenderer>
              </Para>
            </Box>
          </Flex>
          <Flex
            placeContent="flex-start"
            flex="0.2 1 auto"
            flexDirection="column"
            justifyContent="center"
          >
            <ControlCenter
              animationVariants={FADE_IN_ITEM}
              typeOfControl={Controls?.Control_Type}
              setSubmittedAnswer={e => {
                playSound('Button');
                setSubmittedAnswer(e);
              }}
              possibleAnswers={Controls?.Possible_Answers}
              to={nextRoute?.url + WINDOW_HASH || `/${WINDOW_HASH}`}
            />
            {!Controls?.Is_Required && (
              <Button
                variants={FADE_IN_ITEM}
                buttonSize="medium"
                variant="ghost"
                as={MotionLink}
                onPress={() => {
                  const endTimestamp = Date.now();
                  playSound('Button');
                  dispatch(
                    logItem({
                      question_name: normalizedQuestionName,
                      question_type:
                        `${Controls?.Control_Type}${isFollowup ? '-followup' : ''
                        }` || '',
                      // interrupts have no "results" but we still need to provide one for schema to work
                      result: '',
                      collection_name: 'answers',
                      answer_text: 'skip',
                      duration_in_seconds: startTimestamp
                        ? (endTimestamp - startTimestamp) / 1000
                        : 0,
                    }),
                  );
                }}
                mt={7}
                mx="auto"
                to={nextRoute?.url + WINDOW_HASH || `/${WINDOW_HASH}`}
                replace
              >
                <Label textDecoration="underline" variant="l3">
                  {t('Skip Button')}
                </Label>
              </Button>
            )}
          </Flex>
        </motion.div>
      </BodyWrapper>
    </Layout>
  );
};


export default InterruptPage;
