import React from 'react';
import { graphql } from 'gatsby';
import Seo from '@containers/Seo';
import checkFeatureFlag from '@utils/checkFeatureFlag';
import InterruptV1 from './Interrupt-v1';
import InterruptV2 from './Interrupt-v2';

const Interrupt = checkFeatureFlag('MODULES') === 'FLAG_VALID' ? InterruptV2 : InterruptV1;

export const query = graphql`
  query InterruptPage {
    interruptPageBackgroundSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export const Head = () => <Seo />;

export default Interrupt;
